<template>
    <metadata-list name="Owner" url="/api/owners" link="owner" />
</template>

<script>
import MetadataList from '@/components/MetadataList.vue';

export default {
    name: 'OwnerList',

    components: {
        MetadataList
    }
};
</script>
