<template>
    <v-container fluid fill-height class="ma-0 pa-0">
        <ag-table :columns="columns" :rows="rows" :loading="loading" :link="link" :allowAdd="true" @add="add" />
        <name-dialog ref="dialog" />
        <v-snackbar v-model="snackbar" timeout="3000" top outlined :color="snackColor">
            {{ snackText }}
        </v-snackbar>
    </v-container>
</template>

<script>
import axios from 'axios';
import AgTable from '@/components/AgTable.vue';
import NameDialog from '@/components/NameDialog.vue';

export default {
    name: 'MetadataList',

    props: ['url', 'link', 'name'],

    components: {
        AgTable,
        NameDialog
    },

    data() {
        return {
            loading: true,
            snackbar: false,
            snackColor: '',
            snackText: '',
            rows: [],
            columns: [
                { field: 'id', headerName: 'ID', sortable: true, resizable: true, width: 90 },
                { field: 'name', headerName: 'Name', sortable: true, resizable: true, width: 300 }
            ]
        };
    },

    methods: {
        async add() {
            const dialog = this.$refs.dialog;
            if (await dialog.open(`New ${this.name}`)) {
                if (dialog.name) {
                    try {
                        this.loading = true;
                        const response = await axios.post(this.$props.url, {
                            name: dialog.name
                        });
                        this.snackbar = true;
                        this.snackText = 'Saved';
                        this.snackColor = 'success';
                        this.rows = [response.data].concat(this.rows);
                    } catch (error) {
                        this.snackbar = true;
                        this.snackColor = 'error';
                        this.snackText = error;
                    } finally {
                        this.loading = false;
                    }
                }
            }
        }
    },

    async created() {
        this.loading = true;
        const response = await axios.get(this.$props.url);
        this.rows = response.data;
        this.loading = false;
    }
};
</script>
